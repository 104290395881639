import React from "react"
import { BsLinkedin } from "react-icons/bs"
import { RiGithubFill } from "react-icons/ri"

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://github.com/ninjacore" target="_blank">
        <RiGithubFill />
      </a>
      <a href="https://www.linkedin.com/in/lucien-haeller/" target="_blank">
        <BsLinkedin />
      </a>
    </div>
  )
}

export default HeaderSocials
