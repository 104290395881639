import React from "react"
import "./about.css"
import ME from "../../assets/me_about.jpg"
import { FaAward } from "react-icons/fa"
import { VscFolderLibrary } from "react-icons/vsc"

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>10+ Years of Programming</small>
            </article>

            <a href="#portfolio">
              <article className="about__card">
                <VscFolderLibrary className="about__icon" />
                <h5>Projects</h5>
                <small>Cloud, SaaS, DaaS and Linux</small>
              </article>
            </a>
          </div>

          <p>
            I was born in 1992 and grew up with the in&shy;ter&shy;net - from
            My&shy;Space and gam&shy;ing fo&shy;rums to flash games and Xbox
            Live. I vis&shy;ited YouTube for the first time in 2009 and
            ex&shy;pe&shy;ri&shy;enced the in&shy;fa&shy;mous
            Ad&shy;poca&shy;lypse. I only dis&shy;cov&shy;ered Red&shy;dit and
            Imgur later - around 2015.
          </p>
          <p>
            The so&shy;cial in&shy;ter&shy;ac&shy;tions in these worlds have
            al&shy;ways fas&shy;ci&shy;nated me. To this day, I'm most
            in&shy;ter&shy;ested in niche and grass&shy;roots plat&shy;forms
            with vi&shy;brant cre&shy;ative free&shy;dom. The ad&shy;vent of
            vir&shy;tual re&shy;al&shy;ity has been a bless&shy;ing for these
            in&shy;ter&shy;ests. The first real ques&shy;tions of
            tran&shy;shu&shy;man&shy;ism are al&shy;ready un&shy;der&shy;way -
            and with them the ques&shy;tion of what makes us human. The
            emer&shy;gence of ar&shy;ti&shy;fi&shy;cial
            in&shy;tel&shy;li&shy;gence into the main&shy;stream is, of course,
            also giv&shy;ing rise to new ar&shy;gu&shy;ments fa&shy;mil&shy;iar
            from decades of sci&shy;ence fic&shy;tion.
          </p>
          <p>
            As I am ex&shy;pe&shy;ri&shy;enc&shy;ing these times of im&shy;mense
            tech&shy;no&shy;log&shy;i&shy;cal and
            psy&shy;cho&shy;log&shy;i&shy;cal change, I enjoy
            ob&shy;serv&shy;ing these shifts and re&shy;flect&shy;ing on their
            ef&shy;fects on me per&shy;son&shy;ally, as well. The areas where I
            see a lag in de&shy;vel&shy;op&shy;ment, and there&shy;fore most
            often look for and im&shy;ple&shy;ment so&shy;lu&shy;tions
            my&shy;self, are cyber&shy;se&shy;cu&shy;rity, ex&shy;treme
            us&shy;abil&shy;ity, pur&shy;pose&shy;ful ef&shy;fi&shy;ciency, and
            dig&shy;i&shy;tal archiv&shy;ing.
          </p>
          <p>
            My dream is to run a com&shy;pany with both
            ar&shy;ti&shy;fi&shy;cial and human employees.
          </p>

          <article className="about__card">
            <div className="about__content-sparetime">
              <h4>general interests</h4>
              &#62; trends in cyberspace <br />
              &#62; techniques from game development & gamification <br />
              &#62; modern history and geopolitics <br />
              &#62; virtualization, emulation, containerization
            </div>
          </article>

          <p>
            <h3>Favorite Books</h3>
            <ul>
              <li>
                <i>— Do Androids Dream of Electric Sheep?</i>
              </li>
              <li>
                <i>— The Dark Nest Trilogy</i>
              </li>
              <li>
                <i>— Atlas Shrugged</i>
              </li>
              <li>
                <i>— The Picture of Dorian Gray</i>
              </li>
              <li>
                <i>— Thrawn Ascendancy</i>
              </li>
            </ul>
          </p>

          <p>
            <h3>Favorite Tech Channels</h3>
            <ul>
              <li>
                <a
                  href="https://www.youtube.com/@OffByOneSecurity"
                  target="_blank"
                >
                  — Off By One Security
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@Fireship" target="_blank">
                  — Fireship
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@davidbombal" target="_blank">
                  — David Bombal
                </a>
              </li>

              <li>
                <a
                  href="https://www.youtube.com/@professormesser"
                  target="_blank"
                >
                  — Professor Messer
                </a>
              </li>
            </ul>
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  )
}

export default About
