export const data = [
  {
    name: "MVP for a new platform",
    description:
      "I wanted to know if I could create a whole platform by myself end-to-end — including research, design, architecture, frontend and backend. Three distinct user groups will take part in the alpha version very soon.",
    techstack: [
      "React",
      "Next.js",
      "Redux",
      "TailwindCSS",
      "PostgreSQL",
      "Spring",
    ],
    customer: "SoloDev",
    link: null,
  },
  {
    name: "NextGen Backend",
    description:
      "Architect and code the backend of the new platform. Increasing data throughput by a factor of ten. The new microservice architecture is highly scalable, secure and extendable.",
    techstack: [
      "Java",
      "Spring",
      "Docker",
      "Kubernetes",
      "GCP",
      "Jenkins",
      "PostgreSQL",
      "GeoServer",
    ],
    customer: "at Rezatec",
    link: null,
  },
  {
    name: "Full Stack Data App",
    description:
      "Visualization of KPI data from various data sources. I handled the full data-integration process and negotiated with data providers, sysadmins and customers alike.",
    techstack: ["Python", "PostgreSQL", "Talend", "Qlik Sense", "AWS"],
    customer: "at Siemens",
    link: null,
  },

  {
    name: "Quality Monitoring App",
    description:
      "The app has a frontend and a backend version with a shared codebase. Key aspects were asynchronous calls, secure WebSocket connections and upholding data governance.",
    techstack: ["JavaScript", "HTML5", "SCSS", "Node.js", "YAML"],
    customer: "at Siemens",
    link: null,
  },

  {
    name: "Business Website",
    description:
      "I applied a user-centred design to build this website. Customizability, monitoring performance and upholding cybersecurity are key to the final product",
    techstack: [
      "Wordpress",
      "CSS",
      "Google Ads",
      "Google Analytics",
      "Cloudflare",
    ],
    customer: "for MBSR Zug",
    link: "https://www.mbsr-zug.ch",
  },

  {
    name: "JS-based Desktop Game",
    description:
      "A competitive, time-based puzzle game in an 80s Arcade theme. I acted as lead programmer during this university project.",
    techstack: ["JavaScript", "HTML5", "CSS3", "YAML", "Swagger", "REST"],
    customer: "at FHGR",
    link: "https://github.com/ZER0D1VE/point-destroyer",
  },
]
