export const data = [
  {
    category: "Languages of Choice",
    elements: ["Java", "Python", "JavaScript", "Bash"],
  },
  {
    category: "Frontend",
    elements: ["Next.js", "React", "Redux", "TailwindCSS"],
  },
  {
    category: "Backend",
    elements: ["Spring", "PostgreSQL", "Redis"],
  },
  {
    category: "Deployment",
    elements: ["Docker", "GitHub", "Google Cloud Platform"],
  },
  {
    category: "Programming Tools",
    elements: ["VS Code", "GitHub Copilot", "Sublime Text", "Postman"],
  },
  {
    category: "Project Tools",
    elements: ["Miro", "Jira", "Trello"],
  },
]
